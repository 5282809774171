body {
    margin: 0;
    font-family: var(--primFont), sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pad-0 {
    padding: 0px !important;
}

.Liquidation a {
    color: var(--primColor);
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wl-cbox {
    position: relative;
    width: 18px;
    display: inline-block;
    margin: 0 auto;
}

.MuiFormHelperText-root.Mui-error {
    margin: 8px 0 0;
}

.wl-cbox input[type='checkbox'] {
    width: 40px;
    height: 45px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}

.wl-cbox input[type='checkbox'] ~ .far {
    font-weight: 400;
    font-size: 24px;
    color: var(--primColor);
}

.wl-cbox input[type='checkbox']:checked ~ .far {
    font-weight: 900;
    color: var(--primColor);
}

.container {
    max-width: 1240px;
    min-height: 278px;
    height: 100%;
}

.static-container {
    max-width: 1240px;
    height: 100%;
}

.MultiSeller_two .container {
    min-height: 485px;
}

.frame-view {
    margin-left: 30px;
    margin-right: 30px;
}

.customContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: 100%;
}

.Liquidation .customContainer {
    max-width: 1900px;
    margin: 0 auto;
}

.Automobile .customContainer,
.Fundraiser .customContainer,
.liquidationTwo .customContainer,
.CarAuction .customContainer,
.Liquidation .stctPgWrpr {
    max-width: 1600px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.Liquidation .stctPgWrpr {
    min-height: calc(100vh - 476px);
}

.pink-btn {
    background: var(--primColor);
    border: 1px solid var(--primColor);
    color: #fff !important;
    border-radius: 5px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 15px;
    text-transform: capitalize;
    min-width: 165px;
    line-height: 24px;
    cursor: pointer !important;
    display: inline-block;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 1%);
}

.pink-btn.pbtn-sm {
    min-width: 135px;
    width: auto;
    margin: 5px auto 0;
}

.pink-btn:hover,
.pink-btn:focus,
.pink-btn:active {
    background: #fff;
    border-color: var(--primColor);
    color: var(--primColor) !important;
    text-decoration: none;
}

.pink-btn .fa {
    margin-left: 15px;
}

.si-head {
    margin: 50px 0 0;
    font-size: 22px;
    font-weight: 600;
}

.modal-header {
    background: #f2f2f2;
}

.modal-title {
    font-size: 18px;
    line-height: 22px;
    color: #000;
}

.bda-input {
    position: relative;
    margin-bottom: 20px;
}

.bda-input input,
.bda-input select {
    border: 1px solid #5d646c;
    height: 50px;
    border-radius: 5px !important;
    width: 100%;
}

.bda-input label {
    font-size: 12px;
    color: #5d646c;
    margin-bottom: 0;
    position: absolute;
    top: -15px;
    left: 15px;
    background: #fff;
    z-index: 10;
    padding: 5px 10px 0;
    width: auto;
}

.bda-input select {
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
}

.custom-switch {
    padding-right: 2.25rem;
    padding-left: 0;
}

.custom-switch .custom-control-input {
    left: inherit;
    right: 0;
    width: 34px;
    height: 14px;
}

.custom-switch .custom-control-label {
    width: 100%;
}

.custom-switch .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
    background: var(--primColor);
    border-color: var(--primColor);
    right: 0;
    left: inherit;
    width: 34px;
    height: 14px;
    opacity: 0.5;
}

.custom-switch .custom-control-label::after {
    background: var(--primColor);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    right: 22px;
    left: inherit;
    top: 1px;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: var(--primColor);
    right: 0;
}

.mbv-btn,
.ub-btn {
    width: 115px;
    height: 35px;
    border-radius: 5px;
    background: var(--primColor);
    border: 1px solid var(--primColor);
    font-weight: 500;
    font-size: 15px;
    padding: 6px;
    text-align: center;
    color: #fff !important;
    display: inline-block;
}

.mbv-btn:hover,
.mbv-btn:focus,
.ub-btn:hover,
.ub-btn:focus {
    background: #fff;
    border: 1px solid var(--primColor);
    color: var(--primColor) !important;
    text-decoration: none;
}

.MuiTabs-flexContainer {
    /* max-width: 640px; */
    display: inline-block !important;
    border-bottom: 2px solid #2c2c37;
}

.MuiTabs-indicator {
    display: none;
}

.MuiTab-textColorInherit,
.MuiTab-wrapper {
    text-transform: capitalize;
    color: #2c2c37;
    opacity: 1;
}

.MuiTab-textColorInherit.Mui-selected {
    background: #e3e3e3;
}

.MuiPickersBasePicker-container .MuiTabs-flexContainer {
    max-width: initial;
    display: initial !important;
    border-bottom: initial;
}

.MuiPickersBasePicker-container .MuiTabs-indicator {
    display: initial;
}

.MuiPickersBasePicker-container .MuiTab-textColorInherit,
.MuiPickersBasePicker-container .MuiTab-wrapper {
    text-transform: initial;
    color: #fff;
    opacity: initial;
}

.MuiPickersBasePicker-container .MuiTab-textColorInherit.Mui-selected {
    background: initial;
}

.db-bids-wrapper .MuiBox-root {
    padding: 24px 0;
}

.table thead th {
    vertical-align: middle;
    border: none;
    background: #343a40;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
}

.table thead th:last-child,
.table tbody td:last-child {
    text-align: center;
}

.table td img {
    object-fit: cover;
}

.table td,
.table th {
    vertical-align: middle;
}

.os-text {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--primColor);
    margin: 20px auto;
    max-width: 475px;
    font-weight: 500;
}

.noWrap {
    white-space: 'nowrap';
}

* {
    outline: none !important;
}

.sl-pbids {
    width: 375px;
}

.sl-pbids .row {
    margin: 0 -5px;
}

.sl-pbids .row .col-sm-6 {
    padding: 0 5px;
}

.sl-pbids .row .input-group-append {
    padding: 6px;
    height: 40px;
    background: #f8f8f8;
    border: 1px solid #ced4da;
    border-radius: 4px 0 0 4px;
    line-height: 27px;
    width: 44px;
    text-align: center;
}

.sl-pbids .row .form-control {
    height: 40px;
    font-size: 14px;
    padding: 6px;
    margin-left: -1px;
}

.sl-pbids .row button {
    margin: 0 auto;
}

.slidView .slidViewBtnLabel {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    background: #f2f2f2;
    width: max-content;
    padding: 5px 20px 5px 5px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    max-width: 100%;
}

.slidView .slidViewBtnLabel .material-icons {
    padding-right: 5px;
    color: #919191;
}

.slidView .biddingCnt .primButton,
.slidView .biddingCnt .secButton {
    margin-right: auto;
}

.chkcard span p {
    margin: 0;
    font-size: 16px;
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
}

.chkcard span p + p {
    min-width: 40%;
}

.chkcard span:last-child p {
    font-size: 18px;
    font-weight: 700;
}

.chkcard span {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.chkcard {
    margin-bottom: 15px;
    display: block;
    background: #fff;
    border: 2px dashed #c9c9c9;
    border-radius: 10px;
    padding: 25px 20px;
}

.table {
    table-layout: auto;
}

.table .numCell {
    width: 60px;
}

.new-timer {
    white-space: nowrap;
}

.modal-content {
    max-height: calc(90vh);
    overflow: auto;
    height: 100%;
}

.siteBanner.multiple .slick-slider,
.siteBanner.multiple .slick-list,
.siteBanner.multiple .slick-track,
.siteBanner.multiple .slick-slide,
.siteBanner.multiple .slick-slide > div {
    height: inherit;
}

.siteBanner.multiple .slick-dots {
    bottom: 15px;
}

.siteBanner.multiple .slick-dots li button:before {
    font-size: 15px;
}

.ppDivider.MuiDivider-root {
    height: 3px;
    margin: 0px 0 25px;
    background-color: #eee;
}

.triangle-icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: calc((18px / 2) - 2px);
    border-right-width: calc(18px / 4);
    border-bottom-width: 0;
    border-left-width: calc(18px / 4);
    border-color: #ba122b transparent transparent transparent;
    border-radius: 1px;
    padding-bottom: 0px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 0px;
}

.favGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.HeavyEquipment .favGrid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.multiSellerTimerView {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.multiSellerTimerView h6 {
    font-size: 20px;
    font-weight: 500;
    color: #0a0a0a;
    margin-bottom: 0;
    text-align: center;
}

.multiSellerTimerView h6:not(:last-child) {
    margin-right: 25px;
}

.multiSellerTimerView h6 span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #b5b5b5;
    margin-bottom: 5px;
}

.artAuctionTimer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.artAuctionTimer h6 {
    font-size: 18px;
    font-weight: 600;
    color: #0a0a0a;
    margin-bottom: 0;
    text-align: center;
    margin-right: 5px;
}

.ArtAuction.artTheme {
    background-color: #f9f9f9;
}

.artAuctionTimer h6 .periodText {
    font-weight: normal;
}

.timerWrapper .material-icons-outlined {
    font-size: 45px;
    display: block;
    margin-bottom: 15px;
    text-align: center;
}

.timerWrapper .timeLabel {
    font-size: 12px;
    color: #2e2e2e;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
}

.MultiAuction .timerWrapper .timeLabel {
    color: #545454;
}

.MultiAuction .multisellerListCard .timerWrapper .timeLabel::after {
    width: 12%;
}

.MultiAuction .multisellerListCard .timerWrapper .timeLabel::before {
    width: 12%;
}

.MultiAuction .multisellerListCard .primButton button {
    border-radius: var(--btnBorderRadius);
    font-size: 18px;
    font-weight: 400;
    background-color: #f7c62f;
    border: 1px solid #f7c62f;
}

.MultiAuction .multisellerListCard .timerWrapper .timeLabel {
    margin-top: 31px;
    margin-bottom: 31px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}

.timerWrapper .timeLabel::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 5%;
    width: 20%;
    background: #dedede;
    height: 1px;
}

.ArtAuction .notLogged .timerWrapper .timeLabel {
    text-align: left;
}

.ArtAuction .timerWrapper .timeLabel::before {
    left: 0;
}

.timerWrapper .timeLabel::after {
    position: absolute;
    content: '';
    top: 50%;
    right: 5%;
    width: 20%;
    background: #dedede;
    height: 1px;
}

.ArtAuction .timerWrapper .timeLabel::after {
    right: 0;
}

.ArtAuction .mnCardCntnr .timerWrapper .timeLabel::before,
.ArtAuction .mnCardCntnr .timerWrapper .timeLabel::after {
    width: 13%;
    opacity: 0;
}

.ArtAuction .timerWrapper .timeLabel::before,
.ArtAuction .timerWrapper .timeLabel::after,
.ArtAuction
    .rightDrawer.productViewDrawer.multisellerProductDrawer
    .timerWrapper
    .timeLabel::before,
.ArtAuction
    .rightDrawer.productViewDrawer.multisellerProductDrawer
    .timerWrapper
    .timeLabel::after {
    width: 20%;
    opacity: 1;
}

.db-pro-box .media-left .pro-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.MultiSeller .auctionsSearch {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 30px !important;
}

.MuiPagination-ul {
    margin: 15px 0 !important;
}

.productStatus {
    padding: 20px 0;
    height: 30px;
    border-radius: 30px;
    margin: 0;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: max-content;
    min-width: 118px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ArtAuction .productStatus {
    height: auto;
    max-height: 30px;
}

.productStatus h4 {
    font-size: 16px;
    font-weight: 500;
    text-transform: initial;
    margin: 0;
    width: 100%;
    padding: 0 12px;
    border-radius: 4px;
    transform: translateX(3px);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.productStatus h4.winning {
    background: #0cba28;
    color: #fff;
}

.productStatus h4.won {
    background: #018554;
    color: #a4e5cd;
}

.Liquidation .productStatus h4.outbid,
.Liquidation .productStatus h4.losing {
    background: #d71313;
    color: #fff4f1;
}

.Liquidation .productStatus h4.winning {
    background: #316803;
}

.productStatus h4.outbid,
.productStatus h4.losing {
    background: #f1501f;
    color: #fff4f1;
}

.productStatus h4.lost {
    background: #d62f2f;
    color: #ffccbd;
}

.productStatus h4.proxy {
    background: #875486;
    color: #ffffff;
}

.productStatus h4.reserve {
    background: red;
    color: #ffffff;
}

.minMaxContent {
    min-width: max-content;
}

.staticAccordian {
    margin-bottom: 5px;
}

.staticAccordian summary {
    padding: 10px;
    background: #eee;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 700;
}

.staticAccordian .innerContent {
    padding: 10px;
}

.shippingCnt {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.shippingCnt .shippingTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.shippingCnt .customInput {
    margin-right: 10px;
    margin-bottom: 0;
}

.shippingCnt .shippingCharges {
    font-size: 16px;
    margin-top: 15px;
    font-weight: 600;
    background: #dcd3ff;
    padding: 10px;
    color: #1f0788;
    border-radius: 3px;
}

.shippingCnt.primButton button {
    min-width: 40px;
    width: 40px;
}

.shippingCnt label .MuiSwitch-root + .MuiFormControlLabel-label {
    font-weight: 600;
}

.schedulePickup iframe {
    width: 100%;
    border: none;
}

.alrtTotWrpr {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: #ffe8d1;
    color: #ff3900;
    border-radius: 10px;
    margin-top: 15px;
}

.alrtTotWrpr .material-icons {
    margin-right: 15px;
    font-size: 28px;
}

.MultiAuction .main-container {
    min-height: calc(100vh - 550px);
}

.bwDc {
    display: block;
    white-space: pre;
    word-wrap: normal;
}

.filterPanel .customInput .MuiOutlinedInput-adornedEnd {
    padding-right: 4px;
}

.filterPanel .MuiOutlinedInput-input {
    padding: 10px 4px;
}

.MultiSeller_two .refundContainer {
    display: block;
}

.googleLoginBtn.MuiButtonBase-root {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.googleLoginBtn.MuiButtonBase-root img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.googleLoginBtn.MuiButtonBase-root .MuiButton-label {
    text-transform: initial;
}

.googleLogin div,
.googleLogin iframe {
    max-width: initial !important;
}

.googleLogin iframe {
    margin: 0 auto !important;
}

.googleLogin [role='button'] {
    width: 100% !important;
}

.siteAds.adsHome {
    margin: 10px 0;
    /* background: #f5f5f5; */
}

.siteAds.adsHome #deskHomeTop,
.siteAds.adsHome #deskHomeMiddle,
.siteAds.adsHome #deskHomeBottom {
    width: 100%;
    height: 100%;
    aspect-ratio: 11 / 1;
    object-fit: contain;
}

.siteAds.adsHome #mobHomeTop,
.siteAds.adsHome #mobHomeMiddle,
.siteAds.adsHome #mobHomeBottom {
    width: 100%;
    height: 100%;
    aspect-ratio: 350 / 125;
    object-fit: contain;
    display: none;
}

.siteAds.adsHome #mobHomeMiddle,
.siteAds.adsHome #mobHomeBottom {
    aspect-ratio: 340 / 50;
}

.auctionsAdsCnt {
    display: flex;
    gap: 20px;
}

/* .siteAds {
    display: none !important;
} */

.siteAds.adsAuc {
    margin: 10px 0 15px;
    /* max-width: 300px; */
}

.siteAds.adsAuc:not(:empty) {
    min-width: 300px;
}

.siteAds.adsAuc #deskAucLeft,
.siteAds.adsAuc #deskAucRight {
    width: 100%;
    height: auto;
    max-width: 300px;
    aspect-ratio: 300 / 600;
    object-fit: contain;
    object-position: top;
}

.siteAds.adsAuc #deskAucLeft:not(:last-child),
.siteAds.adsAuc #deskAucRight:not(:last-child) {
    margin-bottom: 10px;
}

.siteAds.adsAuc #mobAucTop,
.siteAds.adsAuc #mobAucBottom {
    width: 100%;
    height: 100%;
    aspect-ratio: 270 / 50;
    object-fit: contain;
    display: none;
    max-width: 300px;
    margin: 0 auto;
}

.siteAds.adsAuc #mobAucBottom {
    margin-bottom: 30px;
}

.siteAds.adsProd {
    margin: 10px 0;
}

.siteAds.adsProd #deskProdTop,
.siteAds.adsProd #deskProdMiddle,
.siteAds.adsProd #deskProdBottom {
    width: 100%;
    height: 100%;
    aspect-ratio: 740 / 75;
    object-fit: contain;
}

.siteAds.adsProd #mobProdTop,
.siteAds.adsProd #mobProdMiddle,
.siteAds.adsProd #mobProdBottom {
    width: 100%;
    height: 100%;
    aspect-ratio: 340 / 50;
    object-fit: contain;
    display: none;
}

@media (max-width: 500px) {
    .siteAds.adsHome #deskHomeTop,
    .siteAds.adsHome #deskHomeMiddle,
    .siteAds.adsHome #deskHomeBottom,
    .siteAds.adsAuc #deskAucLeft,
    .siteAds.adsAuc #deskAucRight,
    .siteAds.adsProd #deskProdTop,
    .siteAds.adsProd #deskProdMiddle,
    .siteAds.adsProd #deskProdBottom {
        display: none;
    }

    .siteAds.adsHome #mobHomeTop,
    .siteAds.adsHome #mobHomeMiddle,
    .siteAds.adsHome #mobHomeBottom,
    .siteAds.adsAuc #mobAucTop,
    .siteAds.adsAuc #mobAucBottom,
    .siteAds.adsProd #mobProdTop,
    .siteAds.adsProd #mobProdMiddle,
    .siteAds.adsProd #mobProdBottom {
        display: block;
    }

    .auctionsAdsCnt {
        flex-direction: column;
    }

    .siteAds.adsAuc {
        margin: 10px 0 0;
    }
}

@media (max-width: 700px) {
    .MultiAuction .staticPage img {
        width: 100% !important;
        height: auto !important;
    }
}

@media (max-width: 600px) {
    .table-responsive table {
        /* min-width: 992px; */
    }

    .customContainer {
        padding-left: 30px !important;
        padding-right: 30px !important;
        width: 100%;
    }

    .ep-modal .table tbody td:last-child {
        text-align: left;
    }

    .table thead th {
        font-weight: 500;
        font-size: 12px;
    }

    .table tbody td {
        font-size: 14px;
    }

    .customTabsInner .MuiBox-root {
        padding-left: 0;
        padding-right: 0;
    }

    .table-responsive.lineItemBidTable .tblImgWrpr {
        height: 90%;
    }
}

@media (max-width: 991px) {
    .table-responsive table {
        /* min-width: 992px; */
    }

    .Liquidation.Silverattic .db-bids-wrapper .MuiBox-root {
        min-height: 437px;
    }

    .Liquidation.Silverattic .db-bids-wrapper .preferences-form-wrapper {
        min-height: 495px;
    }

    .Liquidation.Silverattic .db-bids-wrapper .savedCardsCnt {
        min-height: 535px;
    }

    .Liquidation.Silverattic .main-container .container.pt-5 {
        min-height: 560px;
    }
}

@media (max-width: 479px) {
    .search-wrapper .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .MultiSeller .auctionsSearch {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    /* html,
    .table-responsive.lineItemBidTable {
        overflow-x: hidden;
    } */

    .table-responsive.lineItemBidTable {
        overflow-x: hidden;
    }

    .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.d-flex.forgotPasswordCta.fpRm {
    justify-content: center;
}

.d-flex.forgotPasswordCta.fpRm a {
    color: var(--primColor);
}

.react-confirm-alert-body h1 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid var(--primColor);
    padding-bottom: 10px;
}

.react-confirm-alert-button-group {
    justify-content: flex-end !important;
}

.react-confirm-alert-button-group button {
    padding: 7px 45px !important;
    font-size: 16px !important;
}

.react-confirm-alert-button-group button:first-child {
    background-color: var(--primColor);
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.btnWrp.cnfrmBid .btn {
    width: 80px;
    background-color: #000;
    color: white;
    margin-left: 10px;
}

.btnWrp.cnfrmBid .btn.ysBtn {
    background: var(--primColor);
}

.LinesEllipsis.lv-descp {
    display: -webkit-box;
    height: 8ex;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ArtAuction .rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 820px;
    max-width: 90vw;
    background: #fff;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 800px;
    max-width: 90vw;
    background: #fff;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper > div {
    width: 100%;
}

.ArtAuction .rightDrawer {
    /* z-index: 9999 !important; */
}

.ArtAuction .rightDrawer .MuiPaper-root.MuiDrawer-paper > .msSliderInner {
    padding: 60px 60px !important;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper .image-gallery-thumbnail {
    border-color: #c6c6c6;
    border-width: 2px;
}

.ArtAuction .rightDrawer .MuiPaper-root.MuiDrawer-paper .image-gallery-thumbnail {
    border-color: transparent;
    border-radius: 4px;
}

.productViewDrawer.rightDrawer .customContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.productViewDrawer.rightDrawer .pvLt,
.productViewDrawer.rightDrawer .pvRt {
    width: 100%;
}

.productViewDrawer.rightDrawer .pvRt .featuredOn {
    margin-top: 30px;
}

.bottomDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 100%;
    max-width: 90vw;
    background: #fff;
    height: 95%;
    transform: translate(-50%, -50%) !important;
    top: 50%;
    left: 50%;
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.Auctioneer .restrictedState {
    text-align: center;
    max-width: 300px;
    padding-top: 20px;
    margin: auto;
}

.customInput .MuiOutlinedInput-adornedEnd {
    cursor: pointer;
    user-select: none;
}

.Liquidation .mineOffer {
    display: flex;
    align-items: center;
}

.Liquidation .mineOffer .makeMyOffer .customInput {
    margin-bottom: 0px;
    margin-right: 20px;
}

.customInput .MuiInputAdornment-positionEnd {
    margin-left: 0px;
}

.Liquidation .customInput .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
}

.Liquidation .vcaLogin .loginFormBox .customInput .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
}

.viewAll {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    color: var(--primColor);
    font-size: 16px;
    font-weight: 600;
}

.viewAll:hover {
    text-decoration: unset;
    color: var(--primColor);
}

.react-loading-skeleton {
    line-height: initial !important;
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image,
.image-gallery-content.fullscreen .image-gallery-slide,
.image-gallery-content.fullscreen .image-gallery-slide-wrapper,
.image-gallery-content.fullscreen .image-gallery-swipe,
.image-gallery-content.fullscreen .image-gallery-slide img,
.image-gallery-content.fullscreen .image-gallery-slides {
    height: 100%;
}

.image-gallery-content.fullscreen {
    height: 100%;
    max-height: calc(100vh - 80px);
}

.colorPrimary {
    color: var(--primColor);
}

.noRefndPoly p {
    font-weight: 600;
}

.Automobile .multiSellerTimerView h6 {
    font-size: 28px;
    min-width: 32px;
}

.Auctioneer .customContainer {
    max-width: 1400px;
    margin: auto;
}

.swiper-pagination-bullet-active {
    background: var(--primColor) !important;
}

.liquidationTwo .favoriteCheck label {
    color: var(--primColor);
}

.crncyValueinDb {
    white-space: pre;
}

.ArtAuction .table small {
    white-space: pre;
}

.Liquidation .container.auctionsSearch {
    margin-bottom: 50px;
}

.siteBanner.multiple .slick-initialized .slick-slide {
    position: relative;
}

.imgFlNm {
    font-size: 12px;
    word-break: break-all;
    width: 90px;
}

.hrFrm .MuiFormGroup-root {
    display: flex;
}

.regCnt .loginFormBox .hrFrm label.MuiFormControlLabel-root {
    border: 1px dashed rgb(182, 182, 182);
    border-radius: 100px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}

.regCnt .loginFormBox .hrFrm label.MuiFormControlLabel-root.checked {
    border: 1px solid var(--primColor);
    background: var(--primColor);
    color: white;
}

.hrFrm label.MuiFormControlLabel-root .MuiIconButton-root {
    display: none;
}

.CarAuction .main-container {
    min-height: calc(100vh - 290px);
}

.tcktWrpr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.tcktVw {
    /* border: 1.5px solid #efefef; */
    border-radius: 10px;
    padding: 0;
    display: flex;
    position: relative;
    background: aliceblue;
}

.tcktVw .tcktDtls {
    width: 100%;
}

.tcktVw .topVw {
    padding: 20px;
    border-bottom: 1px dashed rgb(201, 201, 201);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tcktVw .topVw h2 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-right: 10px;
}

.tcktVw .topVw span {
    background: #d6e4f5;
    padding: 5px 21px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    white-space: pre;
}

.tcktVw .tcktInfo {
    padding: 20px;
}

.tcktVw .tcktInfo .stTmInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.tcktVw .tcktInfo .stTmInfo label {
    color: gray;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 5px;
}

.tcktVw .tcktInfo .stTmInfo p {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 600;
}

.tcktVw .tcktStatus {
    background: slategray;
    max-width: 90px;
    width: 100%;
    border-radius: 0 7px 7px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    flex-direction: column;
    padding: 20px 0;
}

.tcktVw::after {
    content: '';
    width: 35px;
    height: 18px;
    border-radius: 0 0 130px 130px;
    position: absolute;
    top: -2px;
    right: 73px;
    background: white;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
}

.tcktVw::before {
    content: '';
    width: 35px;
    height: 18px;
    border-radius: 130px 130px 0 0;
    position: absolute;
    bottom: -2px;
    right: 73px;
    background: white;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-top: 1px solid #efefef;
}

.tcktVw .tcktStatus p {
    writing-mode: vertical-lr;
    font-size: 20px;
    letter-spacing: 3px;
    margin-bottom: 0px;
}

.tcktVw .tcktStatus .qrContianer {
    width: 60px;
    height: 60px;
}

.tcktVw .tcktStatus .qrContianer img {
    width: 100%;
    height: 100%;
    filter: grayscale(1);
}

/* Chrome, Safari, Edge, Opera  --- HIDE UP AND DOWN ARROW IN NUMBER FIELD */
.paymWrpr input::-webkit-outer-spin-button,
.paymWrpr input::-webkit-inner-spin-button,
.dntnrSlideWrapper input::-webkit-outer-spin-button,
.dntnrSlideWrapper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.paymWrpr input[type='number'],
.dntnrSlideWrapper input[type='number'] {
    -moz-appearance: textfield;
}

.skyFlwCard [class*='col-'] {
    height: 120px;
}

.skFlwFrm .customInput label,
.skFlwFrm .customSelect label {
    color: #0000008a;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: normal;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.customDialog.mnMsgPopup .actionWrapper {
    display: block;
    margin-top: 0px;
}

.customDialog.mnMsgPopup .MuiPaper-root.MuiDialog-paper {
    max-width: 1000px;
    width: 100%;
}

.customDialog.mnMsgPopup .actionWrapper .clsVw {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-inline-end: 0px;
}

.customDialog.mnMsgPopup .messageBody {
    position: relative;
    z-index: 1;
}

.customDialog.mnMsgPopup .backDrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    opacity: 0.2;
    z-index: -1;
}

.customDialog.mnMsgPopup .messageBody .msgInrBody .chatBblWrpr small {
    color: #323232;
}

.Liquidation .newSlide .customInput {
    margin-bottom: 0px !important;
    max-width: 100px;
}

.Liquidation .newSlide .primButton {
    width: 100%;
}

.Liquidation .card-body .noPrice {
    height: 20px;
}

.Liquidation .newAdded .favoriteCheck {
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.Liquidation .headRt {
    margin-left: 10px;
}

.Liquidation .newSlide .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -23px;
}

.Liquidation .newAdded .favoriteCheck label span {
    font-size: 32px;
}

.Liquidation .newAdded .favoriteCheck label {
    color: var(--primColor);
}

.Liquidation .newSlide .customInput .MuiOutlinedInput-adornedEnd {
    border-radius: 25px 0px 0px 25px;
    max-height: 50px;
}

.Liquidation .grid-card .newSlide .thisplace .primButton button {
    border-radius: 0px 25px 25px 0px;
}

.customDialog.mnMsgPopup .messageBody .msgInrBody {
    height: calc(100% - 70px);
}

.MuiInputLabel-formControl,
.MuiTab-wrapper,
.messagePopupTitle,
.MuiTypography-root,
.MuiFormControl-root,
select option {
    font-family: var(--primFont) !important;
}

.liquidationTwo .loginFormBox .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    position: absolute;
    bottom: -21px;
    left: 0;
}

.pxyBdVl {
    white-space: pre;
}

.amtNoWrp b {
    white-space: pre;
}

.ArtAuction .loginFormBox.becomeSeller form.beSelWrpr {
    background: white;
    padding: 40px;
    border-radius: 10px;
    margin-top: 40px;
}

/* .Liquidation .PaymentModal > div,
.Liquidation #simple-menu,
.Liquidation .productViewDrawer {
    z-index: 99999 !important;
} */

.Liquidation .MuiDialog-root.customDialog {
    z-index: 999999 !important;
}

/* .makeStyles-root-32,
.makeStyles-root-30,
.makeStyles-root-27,
.makeStyles-root-26,
.MuiSnackbar-root {
    z-index: 999999 !important;
} */
.page-showing {
    margin: 15px 0;
}

.ndCTA .primButton button,
.ndCTA .secButton button {
    width: 100% !important;
}

.mySubmitTable table thead tr th:last-child {
    min-width: 250px;
}

@media print {
    .noPrint {
        display: none !important;
    }
}

@media (max-width: 1230px) {
    .Liquidation .mineOffer {
        flex-wrap: wrap;
        justify-content: center;
    }

    .Liquidation .mineOffer .makeMyOffer .customInput {
        margin-bottom: 10px;
        width: 100%;
    }

    .Liquidation .mineOffer .makeMyOffer {
        width: 100%;
    }

    .Liquidation .mineOffer .subOffer {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        max-width: 90vw;
    }

    .pagination-wrapper {
        flex-wrap: wrap;
    }

    .pagination-wrapper {
        flex-wrap: wrap;
    }

    .pagination-wrapper .MuiPagination-root {
        width: 100%;
    }

    .pagination-wrapper .page-showing,
    .pagination-wrapper .MuiPagination-ul {
        margin: 8px auto !important;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .Auctioneer .rightDrawer .MuiPaper-root.MuiDrawer-paper,
    .Liquidation .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 100%;
    }

    .Liquidation.Silverattic .db-bids-wrapper .MuiBox-root {
        min-height: auto;
    }

    .Liquidation.Silverattic .db-bids-wrapper .preferences-form-wrapper {
        min-height: auto;
    }

    .Liquidation.Silverattic .db-bids-wrapper .savedCardsCnt {
        min-height: auto;
    }

    .Liquidation.Silverattic .main-container .container.pt-5 {
        max-width: 1240px;
        min-height: 278px;
        height: 100%;
    }

    .Liquidation .pvMiscActions a,
    .Liquidation .prodInfoSlider .favoriteCheck {
        width: 30px;
        height: 30px;
    }

    .liquidationTwo .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 100%;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        max-width: initial;
        width: 90%;
        overflow: visible;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper .msSliderInner {
        overflow-y: auto;
        -webkit-overflow-scrolling: auto;
    }

    .MultiSeller .db-bids-wrapper .table-responsive .row {
        width: 95vw;
        position: sticky;
        left: 0px;
    }

    .MultiSeller
        .db-bids-wrapper
        .table-responsive
        .row
        .col-md-3
        .nav-standard-top-area-search-input {
        height: 50px;
    }

    .MultiSeller .db-bids-wrapper .table-responsive .row .col-md-3 button.chngStsBtn {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .LiveStock .MuiPagination-ul {
        justify-content: center;
    }

    .stcPgWrpr img {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .hide-web {
        display: none;
    }
}

@media (max-width: 767px) {
    .hide-web {
        font-weight: 700;
        margin-right: 5px;
    }
}

@media screen and (max-width: 475px) {
    .rightDrawer .MuiPaper-root.MuiDrawer-paper .image-gallery-thumbnail {
        margin-top: 15px;
    }
}

@media (max-width: 450px) {
    .googleLogin div > div > div {
        max-width: 100% !important;
    }
}
