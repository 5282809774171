.favoriteCheck {
    width: 40px;
    height: 45px;
    float: right;
    background: rgb(0, 0, 0, 20%);
    border-top-left-radius: 3px;
    position: relative;
}

.MultiSeller_two .gridItemAct .favoriteCheck {
    top: 5px;
}

.favoriteCheck input {
    display: none;
}

.Liquidation .liquidationBuyCard .favoriteCheck {
    margin-top: 5px;
}

.Liquidation .liquidationBuyCard .favoriteCheck label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}

.Liquidation .favoriteCheck label {
    margin-left: 7px;
}

.favoriteCheck label {
    color: #fff;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin: 0;
    margin-top: 5px;
    user-select: none;
}

.ArtAuction .favoriteCheck {
    height: 40px;
}

.favoriteCheck label:hover {
    color: grey;
}

.favoriteCheck label::selection {
    color: none;
    background: transparent;
}

.favoriteCheck label::moz-selection {
    color: none;
    background: transparent;
}

.favoriteCheck input:checked + label {
    color: var(--primColor);
}

.MultiSeller .favoriteCheck input:checked + label {
    color: red;
}

.fullbasket-fav {
    border-radius: 20% !important;
}
